var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-header-index-wide" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 },
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "受理量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.sll),
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-area", {
                        attrs: { "data-source": _vm.chartData.sll },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("今日受理量："),
                    _c("span", [_vm._v(_vm._s(_vm.todaySll))]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 },
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "办结量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.bjl),
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-area", {
                        attrs: { "data-source": _vm.chartData.bjl },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("今日办结量："),
                    _c("span", [_vm._v(_vm._s(_vm.todayBjl))]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 },
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "用户受理量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.isll),
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-bar", {
                        attrs: { datasource: _vm.chartData.isll, height: 50 },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("用户今日受理量："),
                    _c("span", [_vm._v(_vm._s(_vm.todayISll))]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { marginBottom: "24px" },
              attrs: { sm: 24, md: 12, xl: 6 },
            },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: "用户办结量",
                    total: _vm._f("NumberFormat")(_vm.cardCount.ibjl),
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: "指标说明" },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-bar", {
                        attrs: { datasource: _vm.chartData.ibjl, height: 50 },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "footer" }, [
                    _vm._v("用户今日办结量："),
                    _c("span", [_vm._v(_vm._s(_vm.todayIBjl))]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-card",
        {
          attrs: {
            loading: _vm.loading,
            bordered: false,
            "body-style": { padding: "0" },
          },
        },
        [
          _c(
            "div",
            { staticClass: "salesCard" },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": "1",
                    size: "large",
                    "tab-bar-style": {
                      marginBottom: "24px",
                      paddingLeft: "16px",
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extra-wrapper",
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent",
                    },
                    [
                      _c("div", { staticClass: "extra-item" }, [
                        _c("a", [_vm._v("今日")]),
                        _c("a", [_vm._v("本周")]),
                        _c("a", [_vm._v("本月")]),
                        _c("a", [_vm._v("本年")]),
                      ]),
                      _c("a-range-picker", { style: { width: "256px" } }),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { loading: "true", tab: "受理监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("index-bar", {
                                attrs: { title: "受理量统计" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(
                                          _vm.registerTypeList,
                                          function (item, index) {
                                            return _c(
                                              "a-col",
                                              {
                                                key: "registerType" + index,
                                                class: "more-btn",
                                                attrs: { span: 12 },
                                              },
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "primary",
                                                      ghost: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.goPage(index)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "交互监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("bar-multid", {
                                attrs: {
                                  sourceData: _vm.jhjgData,
                                  fields: _vm.jhjgFields,
                                  title: "平台与部门交互量统计",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(
                                          _vm.registerTypeList,
                                          function (item, index) {
                                            return _c(
                                              "a-col",
                                              {
                                                key: "registerType" + index,
                                                class: "more-btn",
                                                attrs: { span: 12 },
                                              },
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "primary",
                                                      ghost: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.goPage(index)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "4", attrs: { tab: "存储监管" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-row",
                                [
                                  _vm.diskInfo && _vm.diskInfo.length > 0
                                    ? _vm._l(
                                        _vm.diskInfo,
                                        function (item, index) {
                                          return _c(
                                            "a-col",
                                            {
                                              key: "diskInfo" + index,
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("dash-chart-demo", {
                                                attrs: {
                                                  title: item.name,
                                                  datasource: item.restPPT,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-card",
                                {
                                  staticStyle: { "margin-bottom": "24px" },
                                  attrs: {
                                    title: "快速开始 / 便捷导航",
                                    bordered: false,
                                    "body-style": { padding: 0 },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "item-group" },
                                    [
                                      _c(
                                        "a-row",
                                        _vm._l(
                                          _vm.registerTypeList,
                                          function (item, index) {
                                            return _c(
                                              "a-col",
                                              {
                                                key: "registerType" + index,
                                                class: "more-btn",
                                                attrs: { span: 10 },
                                              },
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: {
                                                      size: "small",
                                                      type: "primary",
                                                      ghost: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.goPage(index)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.text))]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-row",
        { attrs: { gutter: 12 } },
        [
          _c(
            "a-card",
            {
              class: { "anty-list-cust": true },
              style: { marginTop: "24px" },
              attrs: { loading: _vm.loading, bordered: false },
            },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    size: "large",
                    "tab-bar-style": {
                      marginBottom: "24px",
                      paddingLeft: "16px",
                    },
                  },
                  model: {
                    value: _vm.indexBottomTab,
                    callback: function ($$v) {
                      _vm.indexBottomTab = $$v
                    },
                    expression: "indexBottomTab",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extra-wrapper",
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent",
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          on: { change: _vm.changeRegisterType },
                          model: {
                            value: _vm.indexRegisterType,
                            callback: function ($$v) {
                              _vm.indexRegisterType = $$v
                            },
                            expression: "indexRegisterType",
                          },
                        },
                        [
                          _c(
                            "a-radio-button",
                            { attrs: { value: "转移登记" } },
                            [_vm._v("转移登记")]
                          ),
                          _c(
                            "a-radio-button",
                            { attrs: { value: "抵押登记" } },
                            [_vm._v("抵押登记")]
                          ),
                          _c("a-radio-button", { attrs: { value: "" } }, [
                            _vm._v("所有"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "1",
                      attrs: { loading: "true", tab: "业务流程限时监管" },
                    },
                    [
                      _c("a-table", {
                        attrs: {
                          dataSource: _vm.dataSource1,
                          size: "default",
                          rowKey: "id",
                          columns: _vm.columns,
                          pagination: _vm.ipagination1,
                        },
                        on: { change: _vm.tableChange1 },
                        scopedSlots: _vm._u([
                          {
                            key: "flowRate",
                            fn: function (text, record, index) {
                              return [
                                _c("a-progress", {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    strokeColor: _vm.getPercentColor(
                                      record.flowRate
                                    ),
                                    format: _vm.getPercentFormat,
                                    percent: _vm.getFlowRateNumber(
                                      record.flowRate
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: { loading: "true", tab: "业务节点限时监管" },
                    },
                    [
                      _c("a-table", {
                        attrs: {
                          dataSource: _vm.dataSource2,
                          size: "default",
                          rowKey: "id",
                          columns: _vm.columns2,
                          pagination: _vm.ipagination2,
                        },
                        on: { change: _vm.tableChange2 },
                        scopedSlots: _vm._u([
                          {
                            key: "flowRate",
                            fn: function (text, record, index) {
                              return [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(record.flowRate) + "小时"),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }